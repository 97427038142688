<script setup>
import Layout from "../../layouts/main.vue";
import UploadImage from "../../components/uploadImage.vue";
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted, reactive } from "vue";
import { FormInstance } from "element-plus";

// lấy ngôn ngữ từ pinia store
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { langWeb } from "../../stores/lang";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
import toastr from "toastr";
import mushroom from "cem-primary-api";
import MethodService from "../../service/MethodService";
import LanguageService from "../../service/LanguageService";
import Validate from "../../service/ValidService";
import { Filter } from "mushroomjs";
import { watch } from "vue";

const ruleFormRef = ref(FormInstance);
const router = useRoute();
const routerPush = useRouter();
const propsIdAvatar = ref("");
const showErrorFormatEmail = ref(false);
const showErrorFormatPhoneNumber = ref(false);
const showErrorWithInputIsSpaceForAccount = ref(false);
const showErrorWithInputIsSpaceForName = ref(false);
const showErrorLengthPassword = ref(false);
const showCheckPassword = ref(false);
const groupArray = reactive({ value: [] }); // data quyền hạn sau khi gọi API về
let groupRef = ref([]); // mảng các id user_group --> sau chuyển vào component
let groupRefAPI = ref([]); // dùng để so sánh với groupRef
const infoAccount = ref({
  id: "",
  account: "",
  roles: [],
  disabled: false,
});
const loadingBtn = ref(false);
const validateFlag = ref(false);
// dữ liệu form
let dataFormUser = reactive({
  value: {
    account: "",
    password: "",
    checkPass: "", // check nhập lại mật khẩu
    disabled: false,
    roles: router.name === "CapNhatTaiKhoan" ? [] : ["User"], // -> thêm mới tài khoản thì mặc định chọn user
    fullName: "",
    email: "",
    phone: "",
    avatarId: "",
    department: "",
    sub_department: "",
    position_id: "",
  },
});
let isReadOnly = ref(true);
const departmentArr = ref([]);
const positionArr = ref([]);
const sub_departmentArr = ref([]);
const showErrrorContent = ref(""); // hiển thị lỗi khi validate
// validate form
const validateAccount = (rule, value, callback) => {
  if (value === "") {
    callback(new Error(t("t-place-account")));
  } else {
    if (dataFormUser.value.password !== "") {
      if (!ruleFormRef.value) return;
      ruleFormRef.value.validateField("password", () => null);
    }
    callback();
  }
};
const validatePass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error(t("t-please-input-password")));
  } else if (value && value.trim().length < 6) {
    callback(new Error(t("t_password_min_lenght")));
  } else if (
    dataFormUser.value.account?.length > 0 &&
    value.includes(dataFormUser.value.account)
  ) {
    callback(new Error(t("t_password_not_included_account")));
  } else {
    callback();
  }
};

const validatePassCheck = (rule, value, callback) => {
  if (value === "") {
    callback(new Error(t("t_error_re_enter_password")));
  } else if (value !== dataFormUser.value.password) {
    callback(new Error(t("t_password_not_match")));
  } else {
    callback();
  }
};
const rulesForm = reactive({
  account: [
    // Validate.required,
    { validator: validateAccount, trigger: "blur" },
    Validate.checkSpaceString,
    Validate.checkAccount,
    Validate.checkMaxLength(50),
  ],
  email: [Validate.required, Validate.checkEmail],

  password: [Validate.required, { validator: validatePass, trigger: "blur" }],
  checkPass: [
    { required: true, validator: validatePassCheck, trigger: "blur" },
  ],

  roles: [
    {
      trigger: "change",
      required: true,
      get message() {
        return (
          LanguageService?.lang?.t_please_choose_account_roles ??
          "t_please_choose_account_roles"
        );
      },
    },
  ],
  department: [
    {
      trigger: "change",
      required: true,
      get message() {
        return (
          LanguageService?.lang?.t_please_choose_department ??
          "t_please_choose_department"
        );
      },
    },
  ],
  fullName: [
    Validate.required,
    Validate.checkSpaceString,
    Validate.checkMaxLength(50),
  ],
  phone: [Validate.checkPhone10_11Number],
});

const changeDepartment = (idDepartment) =>
  (dataFormUser.value.department = idDepartment);
const changeSubDepartment = (idSubDepartment) =>
  (dataFormUser.value.sub_department = idSubDepartment);
const changePosition = (idPosition) =>
  (dataFormUser.value.position_id = idPosition);

// check chiều dài mật khẩu
const checkLengthPassword = (password) => {
  showErrorLengthPassword.value = false;
  if (password.length < 6 && password.length > 0) {
    showErrorLengthPassword.value = true;
    showErrrorContent.value = t("t_error_min_password_length");
  } else if (password.includes(dataFormUser.value.account)) {
    showErrorLengthPassword.value = true;
    showErrrorContent.value = t("t_error_password_contain_account");
  } else {
    showErrorLengthPassword.value = false;
    showErrrorContent.value = "";
  }
  if (password.length > 0) {
    showCheckPassword.value = true;
  } else {
    showCheckPassword.value = false;
    dataFormUser.value.checkPass = "";
  }
};

// function thêm mới tài khoản
const fn_ThemMoiTaiKhoan = (formEl) => {
  loadingBtn.value = true;
  validateFlag.value = true;
  if (!formEl) return;

  formEl.validate(async (valid) => {
    if (valid) {
      // thông tin user thêm mới
      const newUser = {
        account: dataFormUser.value.account,
        password: dataFormUser.value.password,
        disabled: dataFormUser.value.disabled,
        roles:
          dataFormUser.value.roles[0] === "Admin"
            ? ["Admin", "User"]
            : dataFormUser.value.roles[0] === "AdminPrimary"
            ? ["AdminPrimary", "User"]
            : dataFormUser.value.roles[0] === "AdminLocal"
            ? ["AdminLocal", "User"]
            : dataFormUser.value.roles[0] === "Edit"
            ? ["Edit", "User"]
            : ["User"],
      };
      // thông tin profile user thêm mới
      const newProfileUser = {
        id: "",
        fullname: dataFormUser.value.fullName.trim(),
        avatarId: dataFormUser.value.avatarId,
        email: dataFormUser.value.email,
        phoneNumber: dataFormUser.value.phone,
        permission: {
          is_editor: dataFormUser.value.roles[0] === "User" ? false : true,
        },
        department_id: dataFormUser.value.department,
        sub_department_id: dataFormUser.value.sub_department,
        position_id: dataFormUser.value.position_id,
      };
      try {
        const res = await mushroom.user.createAsync(newUser);
        if (res.result) {
          newProfileUser.id = res.result;
          // thêm profile cho user vừa tạo thành công
          try {
            const id = await mushroom.profile.createAsync(newProfileUser);
            if (id.result) {
              // thêm quyền hạn cho user vừa tạo thành công
              groupRef.value.forEach(async (item) => {
                await mushroom.user_group.createAsync({
                  user_id: newProfileUser.id,
                  group_id: item,
                });
              });
              setTimeout(() => {
                toastr.success(t("toastr_add_account_success"));
                routerPush.go(-1);
              }, 2000);
            }
          } catch (e) {
            MethodService.showError(e.code);
          }
        }
      } catch (error) {
        if (error?.meta?.field === "$unique_key") {
          toastr.error(t("toastr_account_existed"));
        }
      } finally {
        setTimeout(() => {
          loadingBtn.value = false;
        }, 2000);
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

// function cập nhật tài khoản
const fn_CapNhatTaiKhoan = (formEl) => {
  loadingBtn.value = true;
  validateFlag.value = true;
  if (!formEl) return;

  if (
    !showErrorFormatEmail.value &&
    !showErrorFormatPhoneNumber.value &&
    !showErrorWithInputIsSpaceForAccount.value &&
    !showErrorWithInputIsSpaceForName.value &&
    !showErrorLengthPassword.value
  ) {
    formEl.validate(async (valid) => {
      if (valid) {
        // await getUserGroup();
        // khai báo cập nhật user
        const updateUser = {
          id: router.params.id,
          disabled: dataFormUser.value.disabled,
          roles:
            dataFormUser.value.roles[0] === "Admin"
              ? ["Admin", "User"]
              : dataFormUser.value.roles[0] === "AdminPrimary"
              ? ["AdminPrimary", "User"]
              : dataFormUser.value.roles[0] === "AdminLocal"
              ? ["AdminLocal", "User"]
              : dataFormUser.value.roles[0] === "Edit"
              ? ["Edit", "User"]
              : ["User"],
        };
        console.log("updateUser", updateUser);

        if (
          dataFormUser.value.password &&
          dataFormUser.value.password.length > 0
        ) {
          updateUser.password = dataFormUser.value.password;
        }
        // khai báo cập nhật profile của user
        const updateProfileUser = {
          id: router.params.id,
          fullname: dataFormUser.value.fullName,
          avatarId: dataFormUser.value.avatarId,
          email: dataFormUser.value.email,
          phoneNumber: dataFormUser.value.phone,
          department_id: dataFormUser.value.department,
          sub_department_id: dataFormUser.value.sub_department,
          position_id: dataFormUser.value.position_id,
        };
        console.log(updateProfileUser);
        try {
          const newId = await mushroom.user.partialUpdateAsync(updateUser);
          // nếu cập nhật thành công user
          if (
            newId.result === infoAccount.value.id &&
            dataFormUser.value.disabled === true
          ) {
            toastr.error(t("t_account_not_login"));
            window.localStorage.clear();
            setTimeout(() => {
              let urlLogin = location.origin + "/login";
              window.location.replace(urlLogin);
            }, 2000);
          } else {
            if (newId.result === router.params.id) {
              // cập nhật profile cho user
              try {
                const response = await mushroom.profile.partialUpdateAsync(
                  updateProfileUser
                );
                if (response.result === updateProfileUser.id) {
                  toastr.success(t("toastr_update_account_success"));
                  routerPush.push({ name: "DanhSachNguoiDung" });
                }
              } catch (e) {
                MethodService.showError(e.code);
              }
              // lấy danh sách group_id bị xóa
              let differenceRemove = groupRefAPI.value.filter(
                (x) => !groupRef.value.includes(x)
              );

              if (differenceRemove.length > 0) {
                differenceRemove.forEach(async (item) => {
                  try {
                    const response = await mushroom.user_group.listAsync({
                      filters: Filter.eq("group_id", item)
                        .eq("user_id", router.params.id)
                        .build(),
                    });
                    response.result.forEach(async (x) => {
                      try {
                        await mushroom.user_group.deleteAsync({
                          id: x.id,
                        });
                        await getUserGroup();
                      } catch (error) {
                        console.error("Có lỗi: %o", error);
                      }
                    });
                  } catch (e) {
                    console.error("Có lỗi: %o", e);
                  }
                });
              }

              let differenceAdd = groupRef.value.filter(
                (x) => !groupRefAPI.value.includes(x)
              );
              if (differenceAdd.length > 0) {
                differenceAdd.forEach(async (item) => {
                  try {
                    await mushroom.user_group.createAsync({
                      user_id: router.params.id,
                      group_id: item,
                    });
                    await getUserGroup();
                  } catch (error) {
                    console.error("Có lỗi: %o", error);
                  }
                });
              }
            }
          }
        } catch (error) {
          if (error?.meta?.field === "$unique_key") {
            toastr.error(t("toastr_account_existed"));
          } else {
            if (error.code === 40002) {
              toastr.warning(t("toastr_disable_last_admin"));
            } else if (error.code === 40003) {
              toastr.warning(t("toastr_update_role_last_admin"));
            } else MethodService.showError(error.code);
          }
        } finally {
          setTimeout(() => {
            loadingBtn.value = false;
          }, 2000);
        }
      } else {
        loadingBtn.value = false;
        return false;
      }
    });
  } else loadingBtn.value = false;
};

// function lấy thông tin chi tiết của người dùng
async function getUserInfo() {
  try {
    const response = await mushroom.user.findByIdAsync({
      id: router.params.id,
      fields: "id,account,disabled,roles",
    });
    dataFormUser.value.account = response.result.account;
    dataFormUser.value.disabled = response.result.disabled;
    dataFormUser.value.roles = response.result.roles;
  } catch (e) {
    MethodService.showError(e.code);
  }
}

// function lấy thông tin profile của người dùng
async function getUserProfile() {
  try {
    const response = await mushroom.profile.findByIdAsync({
      id: router.params.id,
      fields:
        "id,fullname,avatarId,email,phoneNumber,department_id,sub_department_id,position_id",
    });
    dataFormUser.value.fullName = response.result?.fullname;
    dataFormUser.value.avatarId = response.result?.avatarId;
    dataFormUser.value.email = response.result?.email;
    dataFormUser.value.phone = response.result?.phoneNumber;
    dataFormUser.value.department = response.result?.department_id;
    dataFormUser.value.sub_department = response.result?.sub_department_id;
    dataFormUser.value.position_id = response.result?.position_id;
    propsIdAvatar.value = response.result?.avatarId;
  } catch (e) {
    MethodService.showError(e.code);
  }
}

// lấy về danh sách các quyền hạn --> sau cho vào pinia
const getGroup = async () => {
  try {
    const response = await mushroom.group.listAsync({ limit: 999 });
    groupArray.value = [];
    response.result.forEach((item) => {
      groupArray.value.push({
        value: item.id,
        label: item.name,
      });
    });
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

// lấy về quyền hạn theo user_id
const getUserGroup = async () => {
  try {
    const response = await mushroom.user_group.listAsync(
      {
        filters: Filter.eq("user_id", router.params.id).build(),
      },
      { enabledCache: false }
    );
    groupRef.value = [];
    groupRefAPI.value = [];
    response.result?.forEach((item) => {
      groupRef.value.push(item.group_id);
    });
    response.result?.forEach((item) => {
      groupRefAPI.value.push(item.group_id);
    });
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getIdImageUpload = (idImageUpload) => {
  dataFormUser.value.avatarId = idImageUpload;
};
const removeImageUpload = (data) => {
  propsIdAvatar.value = data;
  dataFormUser.value.avatarId = data;
};

const removeReadOnly = async () => {
  isReadOnly.value = false;
};

const departmentAPI = async () => {
  try {
    const response = await mushroom.department.listAsync();
    if (response.result) {
      response.result.forEach((element) => {
        departmentArr.value.push({
          value: element.id,
          label: element.name,
        });
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getPositionAPI = async () => {
  try {
    const response = await mushroom.position.getAllAsync();
    if (response.result) {
      response.result.forEach((element) => {
        positionArr.value.push({
          value: element.id,
          label: element.name,
        });
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const subDepartmentAPI = async () => {
  try {
    const response = await mushroom.sub_department.getAllAsync();
    if (response.result) {
      response.result.forEach((element) => {
        sub_departmentArr.value.push({
          value: element.id,
          label: element.name,
        });
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const lowerCase = (event) => {
  dataFormUser.value.account = event.trim().toLowerCase();
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    if (validateFlag.value == true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);

onMounted(async () => {
  let response = JSON.parse(localStorage.getItem("userInfo"));
  infoAccount.value = response.result;
  await departmentAPI();
  await getPositionAPI();
  await subDepartmentAPI();
  await getGroup();
  if (router.name === "CapNhatTaiKhoan" || router.name === "ChiTietTaiKhoan") {
    await getUserInfo();
    await getUserProfile();
    await getUserGroup();
  }
  let activeMenu = document
    .querySelector("#navbar-nav")
    .querySelector('[href="' + "/users/list-users" + '"]');
  activeMenu.classList.add("active");
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="router.name === 'ThemMoiTaiKhoan'">
          {{ $t("t-add-user") }}
        </h3>
        <h3 v-if="router.name === 'ChiTietTaiKhoan'">
          {{ t("t-user-details") }}
        </h3>
        <h3 v-if="router.name === 'CapNhatTaiKhoan'">
          {{ $t("t-update-user") }}
        </h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <el-form
            ref="ruleFormRef"
            :model="dataFormUser.value"
            :rules="rulesForm"
            label-width="150px"
            class="form-user"
          >
            <b-row>
              <b-col md="6">
                <el-form-item
                  :label="t('t-account')"
                  v-if="
                    router.name === 'CapNhatTaiKhoan' ||
                    router.name == 'ChiTietTaiKhoan'
                  "
                >
                  <el-input
                    type="text"
                    clearable
                    disabled
                    v-model="dataFormUser.value.account"
                  />
                </el-form-item>
                <el-form-item
                  :label="t('t-account')"
                  :prop="router.name === 'ThemMoiTaiKhoan' ? 'account' : ''"
                  v-else
                >
                  <el-input
                    type="text"
                    clearable
                    autocomplete="off"
                    :placeholder="t('t-login-account')"
                    :readonly="isReadOnly"
                    v-model="dataFormUser.value.account"
                    @click="removeReadOnly()"
                    @change="lowerCase"
                  />
                </el-form-item>
                <el-form-item
                  :label="t('t-password')"
                  :prop="router.name === 'ThemMoiTaiKhoan' ? 'password' : ''"
                >
                  <el-input
                    type="password"
                    autocomplete="off"
                    :disabled="router.name == 'ChiTietTaiKhoan'"
                    v-model="dataFormUser.value.password"
                    :readonly="isReadOnly"
                    @click="removeReadOnly()"
                    :placeholder="t('t-login-password')"
                    show-password
                    @change="checkLengthPassword(dataFormUser.value.password)"
                  />
                  <span
                    class="error-message"
                    v-if="
                      showErrorLengthPassword &&
                      router.name === 'CapNhatTaiKhoan'
                    "
                    >{{ showErrrorContent }}</span
                  >
                </el-form-item>
                <el-form-item
                  :label="t('t-enter-password-again')"
                  prop="checkPass"
                  v-if="
                    showCheckPassword === true &&
                    router.name === 'CapNhatTaiKhoan'
                  "
                >
                  <el-input
                    type="password"
                    autocomplete="off"
                    v-model="dataFormUser.value.checkPass"
                    :placeholder="t('t-enter-password-again')"
                    show-password
                  />
                </el-form-item>
                <el-form-item :label="t('t-account-roles')" prop="roles">
                  <el-radio-group
                    v-model="dataFormUser.value.roles[0]"
                    :disabled="router.name == 'ChiTietTaiKhoan'"
                  >
                    <el-radio
                      label="Admin"
                      name="type"
                      v-show="infoAccount.roles.includes('Admin')"
                      >Super Admin</el-radio
                    >
                    <el-radio label="AdminPrimary" name="type">Admin</el-radio>
                    <el-radio label="AdminLocal" name="type"
                      >Admin Local</el-radio
                    >
                    <el-radio label="Edit" name="type">Edit</el-radio>
                    <el-radio label="User" name="type">View</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  v-if="
                    router.name === 'CapNhatTaiKhoan' ||
                    router.name === 'ChiTietTaiKhoan'
                  "
                >
                  <el-checkbox
                    :disabled="router.name == 'ChiTietTaiKhoan'"
                    :label="t('t-lock-account')"
                    v-model="dataFormUser.value.disabled"
                    name="type"
                  />
                </el-form-item>
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col md="8">
                    <el-form-item :label="t('t_full_name')" prop="fullName">
                      <el-input
                        type="text"
                        clearable
                        :disabled="router.name == 'ChiTietTaiKhoan'"
                        autocomplete="off"
                        :placeholder="t('t-place-service-fullname')"
                        v-model="dataFormUser.value.fullName"
                        @change="
                          dataFormUser.value.fullName =
                            dataFormUser.value.fullName.trim()
                        "
                      />
                    </el-form-item>
                    <el-form-item label="Email" prop="email">
                      <el-input
                        type="text"
                        autocomplete="off"
                        :disabled="router.name == 'ChiTietTaiKhoan'"
                        v-model="dataFormUser.value.email"
                        placeholder="abc@gmail.com"
                      />
                    </el-form-item>
                    <el-form-item :label="$t('t-phone')" prop="phone">
                      <el-input
                        type="text"
                        autocomplete="off"
                        :disabled="router.name == 'ChiTietTaiKhoan'"
                        placeholder="0966867xxx"
                        v-model="dataFormUser.value.phone"
                      />
                    </el-form-item>
                    <el-form-item
                      :label="$t('t-departments')"
                      prop="department"
                    >
                      <el-select
                        filterable
                        :disabled="router.name == 'ChiTietTaiKhoan'"
                        v-model="dataFormUser.value.department"
                        :placeholder="$t('t-place-departments')"
                        :no-match-text="$t('t-match-data')"
                        :no-data-text="$t('t-no-data')"
                        @change="changeDepartment"
                        popper-class="custom-popper"
                      >
                        <el-option
                          v-for="item in departmentArr"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('t_sub_department')">
                      <el-select
                        filterable
                        :no-match-text="$t('t-match-data')"
                        :disabled="router.name == 'ChiTietTaiKhoan'"
                        v-model="dataFormUser.value.sub_department"
                        :placeholder="$t('t_select_sub_department')"
                        :no-data-text="$t('t-no-data')"
                        @change="changeSubDepartment"
                        clearable
                        popper-class="custom-popper"
                      >
                        <el-option
                          v-for="item in sub_departmentArr"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('t_position_name')">
                      <el-select
                        filterable
                        :no-match-text="$t('t-match-data')"
                        :disabled="router.name == 'ChiTietTaiKhoan'"
                        v-model="dataFormUser.value.position_id"
                        :placeholder="$t('t_select_position')"
                        :no-data-text="$t('t-no-data')"
                        @change="changePosition"
                        clearable
                        popper-class="custom-popper"
                      >
                        <el-option
                          v-for="item in positionArr"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </b-col>
                  <b-col md="4">
                    <upload-image
                      :propsIdAvatar="propsIdAvatar"
                      @uploadImage="getIdImageUpload"
                      @removeImage="removeImageUpload"
                      :disabled="
                        router.name === 'ChiTietTaiKhoan' ? 'true' : 'false'
                      "
                    ></upload-image>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <!-- phân quyền người dùng -->
            <el-form-item :label="$t('t-group-roles')">
              <el-select
                filterable
                v-model="groupRef"
                multiple
                clearable
                :placeholder="$t('t-place-group-roles')"
                :disabled="router.name == 'ChiTietTaiKhoan'"
              >
                <el-option
                  v-for="item in groupArray.value"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <div class="text-center">
              <button
                type="button"
                class="btn btn-soft-secondary btn-border mx-3"
                @click="routerPush.go(-1)"
              >
                {{ $t("t-back") }}
              </button>
              <el-button
                type="button"
                style="height: 40px"
                class="btn btn-secondary btn-border"
                @click="fn_ThemMoiTaiKhoan(ruleFormRef)"
                v-if="router.name === 'ThemMoiTaiKhoan'"
                :loading="loadingBtn"
              >
                {{ $t("t-add") }}
              </el-button>

              <el-button
                type="button"
                style="height: 40px"
                class="btn btn-secondary btn-border"
                @click="fn_CapNhatTaiKhoan(ruleFormRef)"
                v-if="router.name === 'CapNhatTaiKhoan'"
                :loading="loadingBtn"
              >
                {{ $t("t-update") }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
<link type="scss" src="./nguoidung.scss" />